export default class Slide {
    /**
     * Animates an element with a sliding animation
     * @param {Object} el The Vue element
     */
    constructor(el) {
        this.el = el;
        this.data = {
            isSliding: false,
            timeout: null,
        };
        this.minDuration = 300;
    }

    getHeight(vnode) {
        return vnode && vnode.parent ? vnode.parent.elm.scrollHeight : this.el.scrollHeight;
    }

    setElementStyle(styles) {
        Object.keys(styles).forEach(key => {
            this.el.style[key] = styles[key];
        });
    }

    getTransitionDuration() {
        return this.el.style.transitionDuration.replace('ms', '');
    }

    calcDuration(distance, speed) {
        const duration = distance / speed;
        return duration <= this.minDuration ? this.minDuration : duration;
    }

    /**
     * Expands the element immediately on page load
     * @param {Object} vnode The Vue component's VNode
     * @returns {void}
     */
    initializeExpand(vnode) {
        const speed = vnode.context.speed || 1;
        const height = this.getHeight(vnode);
        const duration = this.calcDuration(height, speed);

        this.setElementStyle({
            transitionDuration: `${duration}ms`,
        });
    }

    /**
     * Collapses the element immediately on page load
     * @returns {void}
     */
    initializeCollapse() {
        this.setElementStyle({
            transitionDuration: '0ms',
            maxHeight: '0px',
            overflow: 'hidden',
            display: 'none',
        });
    }

    expand(vnode) {
        if (this.isSliding) {
            clearTimeout(this.data.timeout);
        }
        this.isSliding = true;
        vnode.context.$emit('slide:open-start');

        this.setElementStyle({
            display: null,
        });

        const speed = vnode.context.speed || 1;
        const height = this.getHeight(vnode);
        const duration = this.calcDuration(height, speed);

        this.setElementStyle({
            maxHeight: `${height}px`,
            transitionDuration: `${duration}ms`,
        });

        const timeout = setTimeout(() => {
            this.setElementStyle({
                overflow: null,
                maxHeight: null,
            });

            vnode.context.$emit('slide:open-end');
        }, duration);
        this.data.timeout = timeout;
    }

    collapse(vnode) {
        if (this.isSliding) {
            clearTimeout(this.data.timeout);
        }
        this.isSliding = true;
        vnode.context.$emit('slide:close-start');

        const duration = this.getTransitionDuration();
        const height = this.getHeight(vnode);

        requestAnimationFrame(() => {
            this.setElementStyle({
                maxHeight: `${height}px`,
                transitionDuration: `${duration}ms`,
            });

            requestAnimationFrame(() => {
                this.setElementStyle({
                    maxHeight: '0px',
                    overflow: 'hidden',
                });

                const timeout = setTimeout(() => {
                    this.setElementStyle({
                        display: 'none',
                    });

                    vnode.context.$emit('slide:close-end');
                }, duration);
                this.data.timeout = timeout;
            });
        });
    }

    /**
     * Expands the element and applies .--expanded for more customization
     * Define .--expanded in component styles
     * @param {Object} vnode The Vue component's VNode
     * @returns {void}
     */
    expandByClass(vnode) {
        this.el.className += ' --expanded';
        this.expand(vnode);
    }

    /**
     * Collapses the element and removes .--expanded
     * Define .--expanded in component styles
     * @param {Object} vnode  The Vue component's VNode
     * @returns {void}
     */
    collapseByClass(vnode) {
        this.el.className = this.el.className.replace(' --expanded', '');
        this.collapse(vnode);
    }
}