/* eslint-disable max-len */
export default {
    'lni-auto-suggestion': {
        statusMessages: {
            0: 'No suggestions found.',
            1: 'One suggestion found.',
            2: '{count} suggestions found.',
        },
        instructions: 'Use arrow keys to select.',
        messages: {
            valueMissing: {
                global: 'Please make a selection for "{legend}{labelText}", it\'s required.',
                inline: 'Please make a selection.',
            },
            valueMismatch: {
                global: 'Please select from the list of suggestions for "{legend}{labelText}".',
                inline: 'Please select from the list.',
            },
        },
    },
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Please check "{legend}{labelText}", it\'s required.',
                inline: 'Please check this field.',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Please make a selection for "{legend}{labelText}", it\'s required.',
                inline: 'Please make a selection.',
            },
        },
    },
    'lni-error-container': {
        heading: 'Before we continue:',
        message: 'Please fix the following errors.',
    },
    'lni-file-upload': {
        messages: {
            valueMissing: {
                global: 'Please select a file to upload',
                inline: 'Please select a file.',
            },
            invalidFileSize: {
                global: 'Please select a file no larger than {sizeLimit}',
                inline: 'File size is too large',
            },
            uploadLimit: {
                global: 'You have reached the limit of {fileLimit} files allowed to be uploaded',
                inline: 'File upload limit reached',
            },
            invalidFileType: {
                global: 'The selected file(s) are of the wrong type for uploading. Please select a file with one of the extensions listed: {properExtensionList}',
                inline: 'Please select a valid file type.',
            },
        },
        label: 'Select File(s)',
        maxFileSize: 'Maximum file size:',
        uploadLimit: 'A maximum of ({fileLimit}) files can be uploaded with this submission.',
        validFileTypes: 'Valid file types:',
        delete: 'Delete',
    },
    'lni-file-upload-form': {
        success: 'Your upload was successful.',
        availableUploads: 'Uploaded Files ({available} Available Uploads Remaining)',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'Please enter a valid phone number (Format:XXX-XXX-XXXX)',
                inline: 'Please enter a valid phone number (Format:XXX-XXX-XXXX)',
            },
        },
        extensionLabelText: 'Ext.',
        phoneHelperText: 'Format: XXX-XXX-XXXX',
        phoneFieldsetLabelText: 'Phone',
    },
    'lni-input-search': {
        labelText: 'Search',
        messages: {
            valueMissing: {
                global: 'Please enter "{legend}{labelText}", it\'s required.',
                inline: 'Please fill out this field.',
            },
        },
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'Please enter "{legend}{labelText}", it\'s required.',
                inline: 'Please fill out this field.',
            },
            patternMismatch: {
                global: 'Please use the correct format for "{legend}{labelText}".',
                inline: 'Please use the correct format{example}.',
            },
            typeMismatch: {
                global: 'Please use the correct format for "{legend}{labelText}".',
                inline: 'Please use the correct format{example}.',
            },
            tooLong: {
                global: '{labelText} is limited to {limit} characters.',
                inline: 'This field is limited to {limit} characters.',
            },
            tooShort: {
                global: '{labelText} must be at least {num} characters long. Please add {difference} more character{s}.',
                inline: '{labelText} must be at least {num} characters long. Please add {difference} more character{s}.',
            },
        },
    },
    'lni-input-address': {
        messages: {
            valueMissing: {
                global: 'Please complete the required "{legend}{labelText}" fields.',
                inline: 'Please complete the required fields.',
            },
            badZip: {
                global: 'Please correct the "{legend}{labelText}" fields.',
                inline: 'Please enter the Zip code in a correct format. Examples: "98248" or "98504-4000"',
            },
        },
        requiredFields: 'Please complete the required fields: {fieldsString}.',
        labelText: {
            address1: 'Address 1',
            address2: 'Address 2',
            city: 'City',
            state: 'State',
            zip: 'Zip',
            county: 'County',
        },
    },
    'lni-input-date': {
        messages: {
            invalidMonth: {
                global: 'Please enter a valid month between 01 and 12.',
                inline: 'Please enter a valid month between 01 and 12.',
            },
            monthEnd: {
                global: 'Please enter a valid day for month entered.',
                inline: 'Please enter a valid day for month entered.',
            },
            invalidDay: {
                global: 'Please enter a valid day between 01 and 31.',
                inline: 'Please enter a valid day between 01 and 31.',
            },
            invalidYear: {
                global: 'Please enter a valid year (ex. 1999).',
                inline: 'Please enter a valid year (ex. 1999).',
            },
            leapYear: {
                global: '{value} is not a valid leap year.',
                inline: '{value} is not a valid leap year.',
            },
            dateIsInvalid: {
                global: 'The date value for {labelText} is invalid.',
                inline: 'This date value is invalid.',
            },
            inputMissing: {
                global: 'Please complete the required {labelText} field.',
                inline: 'This field is required.',
            },
        },
        helperText: 'Example: 10/22/2018',
    },
    // TODO: These are not being used (lni-input-text messages are used instead)
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'Please enter {legend} {labelText}, it\'s required.',
                inline: 'Please fill out this field.',
            },
            patternMismatch: {
                global: 'Please use a valid email for {legend} {labelText}.',
                inline: 'Please use a valid email{example}.',
            },
            typeMismatch: {
                global: 'Please use a valid email for {legend} {labelText}.',
                inline: 'Please use a valid email{example}.',
            },
        },
    },
    'lni-input-password': {
        messages: {
            notStrongPassword: {
                global: '',
                inline: 'Passwords must contain at least 3 of the following: An uppercase letter, a lowercase letter, and a number or special character.',
            },
        },
    },
    'lni-modal': {
        closeLabel: 'Close',
    },
    'lni-multiselect': {
        messages: {
            valueMissing: {
                global: 'Please select an option for "{labelText}", it\'s required.',
                inline: 'Please make a selection.',
            },
        },
    },
    'lni-pager': {
        currentPageLabel: 'Current Page, Page {i}',
        goToPageLabel: 'Go to page {i}',
        selectLabel: 'Rows per Page',
        previousPageLabel: 'Previous',
        ellipsisLabel: 'Go to previous range',
        nextPageLabel: 'Next',
        navLabel: 'Pages',
        firstPageLabel: 'Goto page 1',
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Please make a selection for "{legend}{labelText}", it\'s required.',
                inline: 'Please make a selection.',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Please select something for "{legend}{labelText}", it\'s required.',
                inline: 'Please select something.',
            },
        },
    },
    'lni-sharing-widget': {
        content: {
            share: 'Share',
            copy: 'Get link',
            copied: 'Copied',
            email: 'Email',
            print: 'Print',
            tweet: 'Tweet',
            tweetText: '',
            emailSubject: '',
            facebook: 'Share',
            shareVia: 'Share via...',
        },
    },
    'lni-spinner': {
        label: 'Loading...',
    },
    'lni-table': {
        noRowsMessage: 'No rows were returned.',
        errorMessage: 'There was an error retrieving the data.',
    },
    readableList: {
        conjunction: 'and',
    },
};
/* eslint-enable max-len */