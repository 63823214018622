/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Marque "{legend}{labelText}", es obligatorio',
                inline: 'Marque este espacio',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Haga una selección en "{legend}{labelText}", es obligatoria',
                inline: 'Haga una selección',
            },
        },
    },
    'lni-error-container': {
        heading: 'Antes de continuar:',
        message: 'Por favor corrija los siguientes errores',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'Escriba un número telefónico válido (Formato:XXX-XXX-XXXX)',
                inline: 'Escriba un número telefónico válido (Formato:XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'Formato: XXX-XXX-XXXX',
    },
    'lni-input-address': {
        messages: {
            valueMissing: {
                global: 'Complete los campos "{legend}{labelText}" requeridos.',
                inline: 'Complete los campos requeridos.',
            },
            badZip: {
                global: 'Corrija los campos "{legend}{labelText}".',
                inline: 'Introduzca el código postal en el formato correcto. Ejemplos: "98248" o "98504-4000"',
            },
        },
        requiredFields: 'Complete los campos requeridos: {fieldsString}.',
        labelText: {
            address1: 'Dirección 1',
            address2: 'Dirección 2',
            city: 'Ciudad ',
            state: 'Estado',
            zip: 'Código postal',
            county: 'Condado',
        },
    },
    'lni-pager': {
        currentPageLabel: 'Página actual, página {i}',
        goToPageLabel: 'Ir a la página {i}',
        selectLabel: 'Filas por página',
        previousPageLabel: 'Anterior',
        ellipsisLabel: 'Ir al rango anterior',
        nextPageLabel: 'Siguiente',
        navLabel: 'Páginas',
        firstPageLabel: 'Ir a página 1',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'Escriba información en "{legend}{labelText}", es obligatoria',
                inline: 'Llene este espacio',
            },
            patternMismatch: {
                global: 'Por favor use el formato correcto para "{legend}{labelText}"',
                inline: 'Por favor use el formato correcto{example}',
            },
            typeMismatch: {
                global: 'Por favor use el formato correcto para "{legend}{labelText}',
                inline: 'Por favor use el formato correcto{example}',
            },
            tooShort: {
                global: '"{labelText}" debe tener al menos {num} caracteres de longitud. Por favor añada {difference} caracteres más.',
                inline: '"{labelText}" debe tener al menos {num} caracteres de longitud. Por favor añada {difference} caracteres más.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'Escriba información en "{legend}{labelText}", es obligatoria',
                inline: 'Llene este espacio',
            },
            patternMismatch: {
                global: 'Por favor use un correo electrónico válido para "{legend}{labelText}"',
                inline: 'Escriba una dirección de correo electrónica válida',
            },
            typeMismatch: {
                global: 'Por favor use un correo electrónico válido para "{legend}{labelText}"',
                inline: 'Escriba una dirección de correo electrónica válida',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Haga una selección en "{legend}{labelText}", es obligatoria',
                inline: 'Haga una selección',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Seleccione algo para "{legend}{labelText}", es obligatorio',
                inline: 'Seleccione algo',
            },
        },
    },
    'lni-sharing-widget': {
        content: {
            share: 'Compartir',
            copy: 'Obtener enlace',
            copied: 'Copiado',
            email: 'Correo electrónico',
            print: 'Imprimir',
            tweet: 'Tweet',
            tweetText: '',
            emailSubject: '',
            facebook: 'Compartir',
            shareVia: 'Compartir via...',
        },
    },
    'lni-spinner': {
        label: 'Cargando...',
    },
};
/* eslint-enable max-len */